<template>
    <div class="ml-10 mr-10">
        <p>{{ $t("login.loading") }}</p>
        <v-overlay>
            <v-progress-circular
                indeterminate
                size="64"
            ></v-progress-circular>
        </v-overlay>
    </div>
</template>

<script>
export default {
    name: "Redirect",
    mounted() {
        this.$store.commit("SET_BREADCRUMBS", [
            {
                text: this.$t("login.loading"),
                to: "/",
                exact: true
            }
        ]);

        setTimeout(() => {
            if (this.$route.query.path != null) {
                this.$router.push({
                    path: this.$route.query.path
                });
            } else {
                this.$router.push({
                    path: "/"
                });
            }
        }, 3000);
    }
};
</script>

<style scoped>

</style>